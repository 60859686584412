import {useTranslation} from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {

    return (
        <Popup className="howtoredeem-us-popup" title="HOW TO PARTICIPATE">
    <div>
        
    <ol >
        <li>
            Scan the QR code or visit
            <a href="http://www.hideandseekgoldfest.com" target="_blank">
               <span>
                {" "} www.hideandseekgoldfest.com{" "}
                </span>
            </a>
            and land on the customized microsite.
        </li>
        <li>
            Enter the unique code, name, mobile number, Email id &amp; select
            state. Submit via OTP
        </li>
        <li>
            You   will land on the thank you page acknowledging the successful
            registration. You will be contacted if you are a shortlisted winner
        </li>
    </ol>

    </div>


        </Popup>
    );
};

export default HowTORedeemPopup ;