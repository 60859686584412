import MainLayout from "../../layouts/MainLayout";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import footer from "../../assets/images/bottom.png";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken, limit } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import PaymentPage from "../PaymentPage/PaymentPage";
// import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { hideCaptchaBadge, renderCaptcha } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import arrow from "../../assets/images/arrow.png";
import register from "../../assets/images/Register.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";
declare var grecaptcha: any;


type StateResponseProps = {
  state: string;
};


const hardcodedStates = [
  { state: "West Bengal" },
  { state: "Uttarakhand" },
  { state: "Uttar Pradesh" },
  { state: "Tripura" },
  { state: "Telangana" },
  
  { state: "Sikkim" },
  { state: "Rajasthan" },
  { state: "Punjab" },
  { state: "Puducherry" },
  { state: "Odisha" },
  { state: "Nagaland" },
  { state: "Mizoram" },
  { state: "Meghalaya" },
  { state: "Manipur" },
  { state: "Maharashtra" },
  { state: "Madhya Pradesh" },
  { state: "Lakshadweep" },
  { state: "Ladakh" },
  { state: "Kerala" },
  { state: "Karnataka" },
  { state: "Jharkhand" },
  { state: "Jammu and Kashmir" },
  { state: "Himachal Pradesh" },
  { state: "Haryana" },
  { state: "Gujarat" },
  { state: "Goa" },
  { state: "Delhi" },
  { state: "Dadra and Nagar Haveli and Daman and Diu" },
  { state: "Chhattisgarh" },
  { state: "Chandigarh" },
  { state: "Bihar" },
  { state: "Assam" },
  { state: "Arunachal Pradesh" },
  { state: "Andhra Pradesh" },
  { state: "Andaman and Nicobar Islands" },
  // Add more states as needed
];




const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCodeFromUrl = searchParams.get("referral");
  const referralSet = useRef(false);
  const [tokenValue, setTokenValue] = useState("");

  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [citiesFound, setCitiesFound] = useState(true);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        navigate(ROUTES.PAYMENT_PAGE); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    setFilteredNewStates(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    // console.log(filteredCities);
    // console.log(filteredNewStates);
    {
      setFilteredCities(hardcodedStates);
      // setFilteredNewStates(hardcodedStates);
      /*API.stateList()
      .then((states) => {
        setFilteredCities(states.stateList);
        console.log(states.stateList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });*/
    }
  }, []);

  useEffect(() => {
    console.log("Show State Dropdown:", showStateDropdown);
  }, [showStateDropdown]);

  //State Dropdown:
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = filteredCities.filter((state) =>
      state.state.toLowerCase().includes(inputValue)
    );

    setCitiesFound(filtered.length > 0);

    setFilteredNewStates(filtered);
    setShowStateDropdown(true);
  };

  // const handleStateSelect = (selectedState: string) => {
  //   console.log("modal clicke", selectedState);

  //   setStates(selectedState);
  //   setSelectedState(selectedState);

  //   // Show additional fields only if the selected state is Tamil Nadu


  //   // console.log("states", states);
  //   setShowStateDropdown(false);
  // };

  const handleStateFocus = () => {
    setShowStateDropdown(true);
  };

  const handleStateSelect = (selectedState:any) => {
    setSelectedState(selectedState);
    setShowStateDropdown(false);
  };




  const RegisterValidation = Yup.object().shape({
    code: Yup.string().required(
      "Please enter valid Unique Code" ||
      "*That’s not a valid code. Please check and enter the correct code"
    ),
    mobile: Yup.string()
      .required("*Please enter valid 10-digit number")
      .matches(
        /^[56789][0-9]{9}$/,

        "*Please enter valid 10-digit number"
      ),
    name: Yup.string()
      .required("Enter valid name")
      .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid name"),

    agree: Yup.boolean().oneOf(
      [true],
      " Please accept Terms and Conditions"
    ),
     state: Yup.string().required(
      "*Please enter a valid State" || "*Please enter a valid State"
    ),
    email: Yup.string().required(
      "*Please enter a valid Email ID")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "*Please enter a valid Email ID"
      ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
        "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     renderCaptcha('captcha-container', (token) => {
  //       console.log('reCAPTCHA token:', token);
  //       setTokenValue(token);
  //     });
  //   }, 2000); 

  //   return () => clearTimeout(timer);
  // }, []);





  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          code: "",
          mobile: "",
          name: "",
          email: "",
          state:selectedState,
          agree: false,
          // tokenValue
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          // if (!tokenValue) {
          //   errors.setErrors({
          //     agree: "*Please complete captcha challenge",
          //   });
          //   return;
          // }
          if (values.agree) {
            console.log(values);
            startTimer();
            // values.tokenValue = tokenValue
            // setShowOtpForm(true);

            API.register(values)
              .then(() => {
                gtagTrackEvent(GA_EVENTS.Registered);
                setShowOtpForm(true);
                startTimer();
              })
              .catch((err) => {
                const { messageId } = err;
                switch (messageId) {
                  case ERROR_IDS.INVALID_MOBILE:
                    errors.setErrors({
                      mobile: "Invalid Mobile Number",
                    });
                    break;

                  default:
                    errors.setErrors({
                      mobile: err.message,
                    });
                    break;
                }
              });
          } else {
            errors.setErrors({
              agree: "*Please agree to the terms & conditions",
            });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="register-form">
                <p className="form-title ">REGISTER</p>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.code}
                    name="code"
                    maxLength={12}
                    onBlur={handleBlur}
                    placeholder="Enter the Unique Code"
                  />
                </div>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    // maxLength={20}
                    name="name"
                    onBlur={handleBlur}
                    placeholder="Enter Name"
                    pattern="[A-Za-z\s]*"
                  />
                </div>
                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="Enter Mobile Number"
                  />
                </div>

                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    // maxLength={25}
                    onBlur={handleBlur}
                    placeholder="Enter Email ID"
                  />
                </div>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    value={selectedState}
                    onChange={(event) => {
                      handleStateChange(event);
                      handleChange(event);
                    }}
                    name="state"
                    readOnly
                    onFocus={handleStateFocus}
                    onBlur={handleBlur}
                    placeholder="Select State"
                    // onChange={(e) => {
                    //   handleStateChange(e);
                    //   setFieldValue("state", e.target.value);
                    // }}
                  />
                </div>
                {showStateDropdown && (
                  <div className="city dropdown">
                    <ul>
                      {filteredNewStates.map((state) => (
                        <li
                          key={state.state}
                          onClick={() => {
                            handleStateSelect(state.state);
                            setFieldValue("state", state.state);
                          }}
                        >
                          {state.state}
                        </li>
                      ))}
                      {!filteredNewStates.length ? (
                        <li className="nf">Not Found</li>
                      ) : null}
                    </ul>
                  </div>
                )}
                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      I accept the{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u className="terms-link">Terms & Conditions{""}</u>
                      </span>
                      <br />
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      onChange={(e) => {
                        setFieldValue("agree", e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                {errors.code && touched.code && (
                  <p className="error">{errors.code}</p>
                )}
                {!errors.code && errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}

                {!errors.code &&
                  !errors.name &&
                  errors.mobile &&
                  touched.mobile && <p className="error">{errors.mobile}</p>}
                 
                  {!errors.code &&
                  !errors.name &&
                  !errors.mobile &&
                  errors.email &&
                  touched.email && <p className="error">{errors.email}</p>}

                  {!errors.code &&
                  !errors.name &&
                  !errors.mobile &&
                  !errors.email &&
                  errors.state &&
                  touched.state && <p className="error">{errors.state}</p>}

                {!errors.code &&
                  !errors.name &&
                  !errors.mobile &&
                  !errors.email &&
                  !errors.state &&
                  errors.agree &&
                  touched.agree && <p className="error">{errors.agree}</p>}

                {/* <div id="captcha-container"></div> */}
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.PARTICPATED);
          API.verifyOTP(values.otp)
            .then((response) => {
              hideCaptchaBadge();
              // console.log("Access Token:", response.accessToken);
              store.dispatch(setAccessToken(response.accessToken));
              store.dispatch(limit(response.limit));
              // localStorage.setItem("amt", response.amt.toString());

              gtagTrackEvent(GA_EVENTS.Verify_OTP);
              const questionData = response.data;

              // if (response.limit) {
                navigate(ROUTES.PARTICPATED);
              // } else {
              //   navigate(ROUTES.PAYMENT_PAGE);
              // }
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp: "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <div className="input-group">
                  <p className="form-title">ENTER OTP</p>
                  {/* <p className="otp-sub-title">OTP has been sent to your registered<br />mobile number</p> */}
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder="Enter OTP"
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center-timer">{resendTimer}</div>
                  )}
                  <span>Did not receive OTP yet?</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
                <button className="btn btn-primary" type="submit">
                  VERIFY
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
