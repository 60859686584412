import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/header-logo.png";
import Illustration1 from "../../assets/images/illustration1.png";
import Illustration2 from "../../assets/images/illustration2.png";
import Illustration3 from "../../assets/images/illustration3.png";
import mainfooter from "../../assets/images/main-footer.png";
import Illustrationdesktop from "../../assets/images/illustration2.png";

import "./Home.scss";
import { ROUTES } from "../../lib/consts";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

import API from "../../api";
import { Trans, useTranslation } from "react-i18next";
import { useGlobalModalContext } from "../../helpers/GlobalModal";
import { useEffect, useRef, useState } from "react";
import { LANGUAGE, LANGUAGES } from "../../lib/consts";
import { useAuthentication } from "../../hooks/useAuthentication";
import SideMenu from "../../components/SideMenu/SideMenu";
import MenuIcon from "../../assets/images/menu-icon.svg";
import headerlogo from "../../assets/images/header-logo.png";



const Home = () => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuthentication();
  const location = useLocation();
  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      // navigate(ROUTES.PAYOUT_FORM);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        // navigate(ROUTES.PAYOUT_FORM);
      };
      window.addEventListener("popstate", handleLocationChange);
      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, location.pathname, isLoggedIn]);
  const getStarted = () => {
    // navigate(ROUTES.REGISTER);

    console.log("1");
    API.getStarted("start")
      .then((res) => {
        gtagTrackEvent(GA_EVENTS.Get_Started);
        console.log("2 :", res);
        navigate(ROUTES.REGISTER);
      })
      .catch((error) => {
        console.log("2 :", error);
        setShowError(true); // Set showError to true for UI feedback
        console.error("Error in API.getStarted:", error);
        if (error.code === 401) {
          console.log("3 :", 401);
          console.error("User not authenticated:", error.message);
        }
      });
  };
  return (
    <div className="home-page">
      <div className="content">
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />

      <header>


        <img
          src={MenuIcon}
          onClick={() => setShowSideMenu(true)}
          alt="menu"
          className="menu-icon"
        />
        {
          <img
            src={headerlogo}
            alt="logo"
            className="logo"
          // onClick={() => navigate(ROUTES.HOME)}
          />
        }
        {/* <img src={headersublogo} alt="sub-log" className="sub-logo" /> */}
      </header>
        <img src={Illustration1} alt="illustration" className="illustration1" />
        <img src={Illustration2} alt="illustration1" className="illustration2" />
        <img src={Illustration3} alt="illustration2" className="illustration3" />

        <div className="text-wrapper"></div>
        <div className="content-wrapper">
          <div className="get-started-container" onClick={getStarted}>
            <span className="get-started-text">GET STARTED</span>
          </div>
        </div>
      </div>
      <img
        src={mainfooter}
        className="main-footer"
      />
    </div>
  );
};

export default Home;
