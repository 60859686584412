import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Contact us">
      <div style={{ "textAlign": "left" }}>
        For any queries, please contact us at <br />
        Premier Sales Promotions Pvt. Ltd, Mitra Towers, Bangalore - 560001 <br />
        Email :
        <a href="mailto:feedback@bigcity.in" target="_blank">
          {" "}feedback@bigcity.in{" "}
        </a>
        <br />
        Concierge Number - 08040554871 <br />
        Availability - Monday to Friday 10:30 AM to 5:30 PM (Lines closed on public
        &amp; bank holidays)
      </div>

    </Popup>
  );
};

export default ContactUsPopup;
